<template>
  <div>

    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Tambah <b>Daftar Layanan</b>
      </div>
      
      
    </b-alert>

    <!-- Main Row -->
    <div class="row">

      <!-- Form -->
      <div class="col-md-12">
        <!-- <Card :title="'Tambah Biaya Layanan'"> -->
          <!-- <template v-slot:body> -->
          <template>
            <Form
              :form="form"
              :route="'payment-categories'"
            />
          </template>
        <!-- </Card> -->
      </div>

    </div>

  </div>
</template>

<script>

import Card from "@/view/content/Card.vue"
import Form from '@/component/masters/payment-categories/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  data() {
    return {
      form: {
        name: '',
        price: '',
        description: '',
        payment_category_type_id: '',
        doctor_commission: '',
        hospital_income: '',
        employee_commission:"",
        isBundle : false,
        isMedicine : false,
      },
    }
  },

  components: {
    Card,
    Form
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Layanan", route: "" },
      { title: "Layanan", route: "/payment-categories/list" },
      { title: "Tambah" },
    ])
  },

}

</script>